@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  cursor: default;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.metal {
  background-image: linear-gradient(
    to right,
    #195146 0,
    #4ed4b9 22%,
    #8fe2ce 45%,
    #e1f7f1 50%,
    #8fe2ce 55%,
    #4ed4b9 78%,
    #195146 100%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
}

.metalLink {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 9999px;
}

.metalLink::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: background-color 0.3s;
  border-radius: inherit;
  z-index: -1;
}

.metalLink:hover::before {
  background-color: black;
}

/* Loading animation */

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes reverserotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.circle {
  transform-origin: 50% 50%;
  animation: rotation 8s infinite linear;
}

.circle_s {
  animation: reverserotation 4s infinite linear;
}

.truncate-3-lines {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dark-markdown {
  background-color: #171717 !important;
  color: white !important;
}

.dark-markdown code {
  background-color: #1a1a1a !important;
  color: white !important;
}

.dark-markdown pre {
  background-color: #1a1a1a !important;
}

.dark-markdown blockquote {
  border-left-color: #404040 !important;
  color: #d1d1d1 !important;
}
